<template>
  <figure class="relative text-white group aspect-square overflow-hidden">
    <picture v-if="item.img">
      <source v-if="item.img.webp" :srcset="item.img.webp" type="image/webp" />
      <img
        class="w-full object-contain aspect-square bg-dark-blue group-hover:object-cover"
        :class="item.img.class"
        :src="item.img.src"
        :title="item.name"
        :alt="item.alt || `${item.name} - ${item.position}`"
      />
    </picture>
    <figcaption
      v-if="item.name"
      class="text-center absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center bg-dark-blue/30 opacity-0 group-hover:opacity-100 transition-opacity"
    >
      <div class="">
        <h4
          class="font-lemonmilk font-medium text-base sm:text-2xl scale-75 group-hover:scale-100 transition ease-in"
        >
          {{ item.name }}
        </h4>
        <div
          class="font-neutra md:font-medium text-base sm:text-lg"
          v-if="item.position"
        >
          {{ item.position }}
        </div>
      </div>
    </figcaption>
  </figure>
</template>

<script>
  export default {
    name: "TeamFigure",
    props: {
      item: {
        type: Object,
      },
    },
  };
</script>
