<template>
  <div
    class="text-white group aspect-square flex flex-col justify-center items-center bg-dark-blue text-center relative overflow-hidden"
  >
    <div class="font-neutra text-base">Wir suchen:</div>
    <h4 class="font-lemonmilk font-medium text-base sm:text-2xl">
      {{ item.position }}
    </h4>
    <div class="font-neutra text-base mt-3">
      <a
        :href="item.href"
        target="_blank"
        class="text-neon-green p-2 border before:block before:absolute before:w-full before:h-full before:top-0 before:left-0 before:z-10 group-hover:text-dark-blue group-hover:bg-neon-green transition"
        >mehr</a
      >
    </div>
  </div>
</template>

<script>
  export default {
    name: "JobAd",
    props: {
      item: {
        type: Object,
      },
    },
  };
</script>
