<template>
  <section class="my-0">
    <div class="container mx-auto px-3">
      <swiper
        :slides-per-view="2.5"
        :space-between="30"
        :modules="modules"
        :autoplay="autoplay"
        loop
        :breakpoints="breakpoints"
        :speed="100"
      >
        <swiper-slide v-for="client in clients" :key="client.src">
          <picture>
            <source :srcset="client.webp" type="image/webp" />
            <img
              class="aspect-square object-contain"
              :src="client.src"
              :title="client.title"
              :alt="client.alt || client.title"
            />
          </picture>
        </swiper-slide>
      </swiper>
    </div>
  </section>
</template>

<script>
  // import Swiper core and required modules
  import { Autoplay } from "swiper";

  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from "swiper/vue";

  // Import Swiper styles
  import "swiper/css";

  export default {
    name: "LogosSection",
    components: {
      Swiper,
      SwiperSlide,
    },
    setup() {
      const breakpoints = {
        // 700px and up
        700: {
          slidesPerView: 4.5,
        },
        // 1024 and up
        1024: {
          slidesPerView: 7.5,
        },
        // 1024 and up
        1440: {
          slidesPerView: 9,
        },
      };
      const modules = [Autoplay];
      const autoplay = { delay: 1000 };
      return {
        modules,
        breakpoints,
        autoplay,
        clients: [
          {
            title: "Montblanc",
            src: require("@/assets/img/KundenLogos/Montblanc.png"),
            webp: require("@/assets/img/KundenLogos/Montblanc.png?as=webp"),
          },
          {
            title: "Brauerei C.& A. VELTINS",
            src: require("@/assets/img/KundenLogos/Veltins.png"),
            webp: require("@/assets/img/KundenLogos/Veltins.png?as=webp"),
          },
          {
            title: "VELTINS V+",
            src: require("@/assets/img/KundenLogos/Vplus.png"),
            webp: require("@/assets/img/KundenLogos/Vplus.png?as=webp"),
          },
          {
            title: "VELTINS Grevensteiner",
            src: require("@/assets/img/KundenLogos/Grevensteiner.png"),
            webp: require("@/assets/img/KundenLogos/Grevensteiner.png?as=webp"),
          },
          {
            title: "VELTINS Fassbrause",
            src: require("@/assets/img/KundenLogos/Fassbrause.png"),
            webp: require("@/assets/img/KundenLogos/Fassbrause.png?as=webp"),
          },
          {
            title: "GLOBEGURU",
            src: require("@/assets/img/KundenLogos/GLOBEGURU.png"),
            webp: require("@/assets/img/KundenLogos/GLOBEGURU.png?as=webp"),
          },
          {
            title: "Dr. Peter Hartig",
            src: require("@/assets/img/KundenLogos/DrPH.png"),
            webp: require("@/assets/img/KundenLogos/DrPH.png?as=webp"),
          },
          {
            title: "AMARIS ELEMENTS",
            src: require("@/assets/img/KundenLogos/Amaris.png"),
            webp: require("@/assets/img/KundenLogos/Amaris.png?as=webp"),
          },
          {
            title: 'Vincentz Network GmbH & Co. KG - Netzwerk "möbel kultur"',
            src: require("@/assets/img/KundenLogos/Holzmann.png"),
            webp: require("@/assets/img/KundenLogos/Holzmann.png?as=webp"),
          },
        ],
      };
    },
  };
</script>
