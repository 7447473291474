<template>
  <footer class="py-5">
    <div
      class="container mx-auto flex flex-col md:flex-row justify-center items-center md:items-start text-center md:text-left md:justify-evenly max-w-5xl px-8 gap-8"
    >
      <div class="">
        <img
          src="@/assets/img/VESCH_Logo.png"
          class="h-7 w-auto max-w-none align-baseline mb-5"
        />
        <div class="font-neutra text-base text-gray not-italic">
          VESCH DIGITAL GmbH<br />
          Große Bleichen 10<br />
          20354 Hamburg<br />
          <a href="mailto:hallo@vesch.de" class="hover:text-white"
            >hallo@vesch.de</a
          ><br />
          <a href="tel:0049403003990">0049 / 40 / 300 399 0</a>
        </div>
      </div>
      <div class="w-full md:w-auto">
        <div
          class="font-lemonmilk font-medium uppercase text-xl align-baseline mb-5"
        >
          Explore
        </div>
        <ul
          class="font-neutra text-base text-gray not-italic leading-8 flex md:block justify-around flex-wrap gap-3"
        >
          <li v-for="item in menu" :key="item.title">
            <router-link :to="item.router" class="text-gray hover:text-white">{{
              item.title
            }}</router-link>
            <br />
          </li>
        </ul>
      </div>
      <div class="hidden">
        <div class="">
          <div class="font-lemonmilk font-medium uppercase text-xl mb-5">
            Social Media
          </div>
        </div>

        <div class="flex flex-column justify-center">
          <a href="https://www.instagram.com/vesch_digital/"
            ><img class="h-12 mr-5" src="@/assets/img/Instagram-Icon.png"
          /></a>
          <a
            href="https://www.linkedin.com/search/results/all/?keywords=vesch%20digital%20gmbh&origin=RICH_QUERY_SUGGESTION&position=2&searchId=1ed4bb7e-a878-4d28-b2b1-8b8dc723fe38&sid=B__"
            ><img class="h-12" src="@/assets/img/LinkedIn-Icon.png"
          /></a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
  export default {
    name: "PageFooter",
    props: {},
    emits: [],
    components: {},
    setup() {
      const menu = [
        { title: "Home", router: { name: "home" } },
        { title: "Leistungen", router: { name: "home", hash: "#leistungen" } },
        { title: "Über uns", router: { name: "home", hash: "#ueber_uns" } },
        { title: "Kontakt", router: { name: "home", hash: "#kontakt" } },
        { title: "Impressum", router: { name: "impressum" } },
        { title: "Datenschutz", router: { name: "datenschutz" } },
        { title: "Cookies", router: { name: "cookies" } },
      ];

      return {
        menu,
      };
    },
  };
</script>
