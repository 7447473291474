<template>
  <h2 class="font-neutra text-2xl md:text-2xl">
    <slot></slot>
  </h2>
</template>

<script>
  export default {
    name: "Heading-2",
  };
</script>
