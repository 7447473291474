<template>
  <div
    class="-sm:absolute -sm:overflow-hidden -sm:right-0 -sm:top-10 -sm:bg-dark-blue -sm:z-50 -sm:transition-[width] -sm:duration-300 -sm:block -sm:h-screen"
    :class="{ '-sm:w-[250px]': modelValue, '-sm:w-0': !modelValue }"
  >
    <div class="flex flex-col sm:flex-row gap-8 sm:gap-2 md:gap-8 -sm:pt-4">
      <router-link
        class="font-neutra font-normal -sm:pl-6 uppercase"
        :class="item.css"
        :to="item.router"
        :key="item.title"
        v-for="item in menu"
        @click="$emit('update:modelValue', false)"
        >{{ item.title }}</router-link
      >
    </div>
  </div>
</template>
<script>
  export default {
    emits: ["update:modelValue"],
    props: {
      modelValue: Boolean,
    },
    setup() {
      const menu = [
        { title: "Home", router: { name: "home" }, css: "sm:hidden" },
        { title: "Leistungen", router: { name: "home", hash: "#leistungen" } },
        { title: "Über uns", router: { name: "home", hash: "#ueber_uns" } },
        { title: "Kontakt", router: { name: "home", hash: "#kontakt" } },
        { title: "Impressum", router: { name: "impressum" } },
        {
          title: "Datenschutz",
          router: { name: "datenschutz" },
          css: "sm:hidden",
        },
      ];

      return {
        menu,
      };
    },
  };
</script>
