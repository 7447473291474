<template>
  <div
    class="md-landscape:w-1/2 md-landscape:pl-8 circle-element mb-16 md:mb-0"
  >
    <div class="lg:max-w-xl mx-3 md:mx-auto text-center md-landscape:text-left">
      <CircleHeadline><slot name="title"></slot></CircleHeadline>
      <div class="font-neutra text-lg md:text-2xl">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
  import CircleHeadline from "./CircleHeadline.vue";

  export default {
    props: ["image", "active"],
    components: {
      CircleHeadline,
    },
    setup() {
      return {};
    },
  };
</script>
