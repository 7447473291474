<template>
  <div
    class="fixed bottom-0 bg-neon-green p-10 md:p-20 w-full text-dark-blue font-neutra md:w-1/2 right-0 z-50"
    v-show="showConsent || showCookieConsent"
  >
    <div class="max-w-lg mx-auto">
      <div class="text-3xl font-extralight">
        Wir nutzen
        <router-link
          :to="{ name: 'cookies' }"
          class="underline hover:no-underline"
          >Cookies</router-link
        >
        zu unterschiedlichen Zwecken, unter anderem zur Analyse und für
        personalisierte Marketing-Mitteilungen.
      </div>
      <div class="text-lg font-extralight mt-10 flex justify-between gap-4">
        <button
          @click.prevent="acceptAll"
          class="bg-dark-blue text-neon-green py-2 px-3"
        >
          allen zustimmen
        </button>
        <button @click.prevent="declineAll">alle ablehnen</button>
      </div>
      <div class="flex gap-4 mt-4">
        <router-link
          :to="{ name: 'impressum' }"
          class="text-base font-extralight"
          >Impressum</router-link
        >
        <router-link
          :to="{ name: 'datenschutz' }"
          class="text-base font-extralight"
          >Datenschutz</router-link
        >
      </div>
    </div>
  </div>
</template>
<script>
  import { ref } from "vue";

  export default {
    emits: ["update:showCookieConsent"],
    props: {
      showCookieConsent: Boolean,
    },
    components: {},
    setup(props, { emit }) {
      const storageAvailable = (type) => {
        let storage;
        try {
          storage = window[type];
          var x = "__storage_test__";
          storage.setItem(x, x);
          storage.removeItem(x);
          return true;
        } catch (e) {
          return (
            e instanceof DOMException &&
            // everything except Firefox
            (e.code === 22 ||
              // Firefox
              e.code === 1014 ||
              // test name field too, because code might not be present
              // everything except Firefox
              e.name === "QuotaExceededError" ||
              // Firefox
              e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage &&
            storage.length !== 0
          );
        }
      };
      const close = () => {
        showConsent.value = false;
        emit("update:showCookieConsent", false);
      };
      const acceptAll = () => {
        if (storageAvailable("localStorage")) {
          localStorage.setItem("cookies.acceptAll", true);
          close();
          window.location.reload();
        }
      };
      const declineAll = () => {
        localStorage.removeItem("cookies.acceptAll");
        close();
      };

      const showConsent = ref(
        !(
          storageAvailable("localStorage") &&
          localStorage.getItem("cookies.acceptAll")
        )
      );

      return {
        showConsent,
        acceptAll,
        declineAll,
      };
    },
  };
</script>
