<template>
  <section class="my-20">
    <div class="">
      <div class="flex flex-wrap justify-center flex-row">
        <component
          :is="item.type"
          :item="item"
          v-for="item in team"
          :key="item.name"
          class="w-1/2 md:w-1/3"
          :class="item.class"
        />
      </div>
    </div>
  </section>
</template>

<script>
  import TeamFigure from "./partials/TeamFigure.vue";
  import JobAd from "./partials/JobAd.vue";
  export default {
    name: "LogosSection",
    components: { TeamFigure, JobAd },

    setup() {
      return {
        team: [
          {
            type: "TeamFigure",
            name: "Ralf",
            position: "Managing Director",
            img: {
              src: require("@/assets/img/Team/Ralf.jpg"),
              webp: require("@/assets/img/Team/Ralf.jpg?as=webp"),
            },
          },
          {
            type: "TeamFigure",
            name: "Oliver",
            position: "Head of Development",
            img: { src: require("@/assets/img/Team/Oliver.jpg") },
          },
          {
            type: "TeamFigure",
            name: "Tom",
            position: "Art-Director",
            img: { src: require("@/assets/img/Team/Tom.jpg") },
          },
          // {
          //   type: "TeamFigure",
          //   name: "Erik",
          //   position: "Beratung & Sales",
          //   img: { src: require("@/assets/img/Team/Erik.jpg") },
          // },
          {
            type: "TeamFigure",
            name: "Susan",
            position: "Projektmanagement",
            img: { src: require("@/assets/img/Team/Susan.jpg") },
          },
          // {
          //   type: "JobAd",
          //   position: "Head-of-Performance-Marketing (m/w/d)",
          //   href: "download/220601 VD HR Performance.pdf",
          // },
          {
            type: "TeamFigure",
            name: "Louisa",
            position: "Performance & Redaktion",
            img: { src: require("@/assets/img/Team/Louisa.jpg") },
          },
          {
            type: "TeamFigure",
            name: "Noah",
            position: "Performance & Video-Creation",
            img: { src: require("@/assets/img/Team/Noah.jpg") },
          },
          {
            type: "TeamFigure",
            name: "Tim",
            position: "Performance & Art Direction",
            img: { src: require("@/assets/img/Team/Tim.jpg") },
          },
          {
            type: "TeamFigure",
            name: "Sebastian",
            position: "Freelance Performance & Art-Direction",
            img: {
              src: require("@/assets/img/Team/Freelance_Sebastian.jpg"),
            },
          },
          {
            type: "TeamFigure",
            name: "Martin",
            position: "Freelance Performance-Kampagnen",
            img: {
              src: require("@/assets/img/Team/Freelance_Martin.jpg"),
            },
          },
          // {
          //   type: "TeamFigure",
          //   name: "Emma & Aika",
          //   position: "Feel-Good-Manager",
          //   img: { src: require("@/assets/img/Team/Emma_Aika.jpg") },
          // },
          // {
          //   type: "TeamFigure",
          //   name: "VESCH DIGITAL",
          //   position: "Hamburg",
          //   img: {
          //     src: require("@/assets/img/VESCH_DIGITAL_Hamburg.jpg"),
          //     webp: require("@/assets/img/VESCH_DIGITAL_Hamburg.jpg?as=webp"),
          //   },
          // },
          {
            type: "TeamFigure",
            img: {
              src: require("@/assets/img/VESCH_Logo_600.png"),
              webp: require("@/assets/img/VESCH_Logo_600.png?as=webp"),
              class: "p-10",
            },
            class: "md:hidden",
          },
        ],
      };
    },
  };
</script>
