<template>
  <h2
    class="uppercase font-lemonmilk text-accent text-2xl md:text-3xl lg:text-4xl after:content-[' '] after:bg-accent after:block lg:after:md:h-1 after:-ml-2 after:w-10 after:mt-4"
  >
    <slot />
  </h2>
</template>

<script>
  export default {
    name: "CircleHeadline",
  };
</script>
