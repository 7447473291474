<template>
  <section class="min-h-screen flex flex-col justify-center" id="home">
    <div class="container mx-auto h-full px-8">
      <div
        class="flex lg:gap-8 portrait:gap-0 text-center lg:text-left portrait:text-center justify-between"
      >
        <div class="max-w-4xl mx-auto flex flex-col justify-center">
          <Heading-1>
            High<br />
            <span class="text-neon-green">Performance</span>
            Marketing
          </Heading-1>
          <PhoneMockup class="lg:hidden portrait:block my-6" />
          <Heading-2 class="mt-6">
            Wir sind eine Performance-Marketing-Agentur, die
            <span class="text-neon-green"
              >innovative Technologien und digitalen Markenaufbau</span
            >
            verbindet und so deine digitalen Umsätze messbar und profitabel
            beschleunigt.
          </Heading-2>
          <Heading-2 class="mt-6">
            Führende Marken, ambitionierte Startups und traditionelle
            Mittelständler vertrauen uns seit über 20 Jahren.
          </Heading-2>
        </div>
        <PhoneMockup class="hidden lg:block portrait:hidden" />
      </div>
      <div class="lg:flex flex-row items-center justify-center relative hidden">
        <img
          class="h-8 lg:mb-6 mt-5 scroll-icon"
          src="@/assets/img/Scroll_Icon.png"
        />
      </div>
    </div>
  </section>
</template>

<script>
  import Heading1 from "./partials/Heading1.vue";
  import Heading2 from "./partials/Heading2.vue";
  import PhoneMockup from "./IGPhoneMockup.vue";
  export default {
    name: "HeroSection",
    components: {
      Heading1,
      Heading2,
      PhoneMockup,
    },
  };
</script>
<style scoped>
  @keyframes ScrollDownButtonBounce {
    0% {
      transform: translateY(-8%);
    }
    100% {
      transform: translateY(8%);
    }
  }
  .scroll-icon {
    animation-name: ScrollDownButtonBounce;
    animation-duration: 0.8s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-timing-function: ease-in-out;
  }
</style>
