<template>
  <div class="bg-dark-blue text-white pt-20">
    <Hero />
    <Logos />
    <Helden />
    <Services />
    <Sellingpoints />
    <Contact @showCookieConsent="$emit('showCookieConsent')" />
  </div>
</template>

<script>
  import Hero from "@/components/HeroSection.vue";
  import Helden from "@/components/HeldenSection.vue";
  import Logos from "@/components/LogosSection.vue";
  import Services from "@/components/ServicesSection.vue";
  import Sellingpoints from "@/components/SellingpointsSection.vue";
  import Contact from "@/components/ContactSection.vue";

  export default {
    name: "PageComponent",
    components: {
      Hero,
      Helden,
      Logos,
      Services,
      Sellingpoints,
      Contact,
    },
  };
</script>
