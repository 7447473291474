<template>
  <div class="fixed top-0 bg-dark-blue z-40 w-full">
    <div
      class="h-14 p-3 container mx-auto flex relative justify-between items-center"
    >
      <div>
        <router-link :to="{ name: 'home' }" @click="showMenu = false">
          <img class="h-9" src="@/assets/img/VESCH_Logo.png" />
        </router-link>
      </div>
      <div class="sm:hidden">
        <img
          class="h-5 cursor-pointer"
          src="@/assets/img/Burger_Navigation.png"
          @click="showMenu = !showMenu"
        />
      </div>
      <MenuWrapper v-model="showMenu" />
    </div>
  </div>
</template>

<script>
  import { ref } from "vue";
  import MenuWrapper from "./MenuWrapper";
  export default {
    name: "PageNavbar",
    props: { showImpressum: Boolean, showDataPrivacy: Boolean },
    components: {
      MenuWrapper,
    },
    setup() {
      var showMenu = ref(false);
      return {
        showMenu,
      };
    },
  };
</script>
