<template>
  <section>
    <div class="container mx-auto py-10 scroll-mt-9" id="kontakt">
      <SectionSubHeading accent>Kontakt</SectionSubHeading>
      <SectionHeading white>
        Teams<span class="text-accent">-</span>Einladung
      </SectionHeading>
      <div
        class="flex lg:flex-row max-w-3xl mx-auto flex-col-reverse px-16 lg:px-0 mt-8"
      >
        <div class="flex items-center">
          <p class="font-neutra text-xl lg:text-3xl text-center">
            Lass uns herausfinden wie wir auch deine Online-Umsätze steigern
            können. Gemeinsam besprechen wir deinen Status Quo, deine Ziele und
            wie wir dir helfen können.
          </p>
        </div>
        <div class="flex items-center lg:pl-10">
          <picture>
            <source
              srcset="@/assets/img/Illustration_Dark_mode.png?as=webp"
              type="image/webp"
            />
            <img
              src="@/assets/img/Illustration_Dark_mode.png"
              alt="Placeholder"
            />
          </picture>
        </div>
      </div>
      <div class="text-center mt-14" v-if="showCalendly">
        <!-- Calendly Inline-Widget Beginn -->
        <div
          class="calendly-inline-widget xl:px-4 md:px-0"
          data-url="https://calendly.com/vesch/60min?hide_gdpr_banner=1"
          style="min-width: 280px; height: 1050px"
        ></div>
        <component
          :is="'script'"
          type="text/javascript"
          src="https://assets.calendly.com/assets/external/widget.js"
          async
        ></component>
        <!-- Calendly Inline-Widget Ende -->
      </div>
      <div
        v-else
        class="text-center mt-10 py-10 text-neon-green cursor-pointer"
        @click.prevent="handleShowCookieConsent"
      >
        Bitte stimme den <u>Cookies</u> zu um einen Termin vereinbaren zu
        können.
      </div>
    </div>
  </section>
</template>

<script>
  import { computed } from "vue";

  import SectionSubHeading from "./SectionSubHeading.vue";
  import SectionHeading from "./SectionHeading.vue";

  export default {
    name: "ContactSection",
    props: {
      showCookieConsent: Boolean,
    },
    emits: ["showCookieConsent"],
    components: {
      SectionSubHeading,
      SectionHeading,
    },
    setup(props, { emit }) {
      const showCalendly = computed(() =>
        localStorage.getItem("cookies.acceptAll")
      );

      const handleShowCookieConsent = (e) => {
        e.preventDefault();
        emit("showCookieConsent", true);
      };

      return {
        showCalendly,
        handleShowCookieConsent,
      };
    },
  };
</script>
<style>
  @media screen and (min-width: 1200px) {
    .calendly-inline-widget {
      height: 664px !important;
    }
  }
</style>
