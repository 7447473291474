import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/impressum",
    name: "impressum",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "impressum" */ "../views/ImpressumView.vue"),
  },
  {
    path: "/datenschutz",
    name: "datenschutz",
    component: () =>
      import(
        /* webpackChunkName: "datenschutz" */ "../views/DatenschutzView.vue"
      ),
  },
  {
    path: "/cookies",
    name: "cookies",
    component: () =>
      import(/* webpackChunkName: "cookies" */ "../views/CookiesView.vue"),
  },
];

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (to.hash) {
        return {
          el: to.hash,
        };
      }
      return { top: 0 };
    }
  },
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
