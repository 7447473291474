<template>
  <h3
    class="text-center font-lemonmilk font-medium leading-snug text-4xl sm:text-[3rem] lg:text-[4.4rem]"
    :class="classes"
  >
    <slot />
  </h3>
</template>

<script>
  import { computed } from "vue";
  export default {
    name: "SectionHeading",
    props: {
      white: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    setup(props) {
      const classes = computed(() => {
        return props.white ? "text-white" : "text-black";
      });

      return {
        classes,
      };
    },
    components: {},
  };
</script>
