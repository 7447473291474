<template>
  <h2
    class="uppercase font-lemonmilk text-center text-2xl md:text-2xl leading-tight tracking-[.2rem]"
    :class="classes"
  >
    <slot />
  </h2>
</template>

<script>
  import { computed } from "vue";
  export default {
    name: "SectionSubHeading",
    props: {
      accent: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    setup(props) {
      const classes = computed(() => {
        return props.accent ? "text-accent" : "text-black";
      });

      return {
        classes,
      };
    },
    components: {},
  };
</script>
