<template>
  <h1
    class="font-lemonmilk font-medium leading-tight text-4xl sm:text-[3rem] md:text-[4.4rem] xl:text-[6rem]"
  >
    <slot></slot>
  </h1>
</template>

<script>
  export default {
    name: "Heading-1",
  };
</script>
