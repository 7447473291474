<template>
  <div class="frame mx-auto relative">
    <picture>
      <source
        srcset="@/assets/img/IG-Iphone-Frame-Mockup.png?as=webp"
        type="image/webp"
      />
      <img src="@/assets/img/IG-Iphone-Frame-Mockup.png" class="w-full" />
    </picture>

    <div class="banner-video">
      <video
        :autoplay="autoplay"
        :muted="autoplay"
        loop
        playsinline
        class="lazy"
        ref="video"
        poster="@/assets/video/poster.jpg"
      >
        <source
          data-src="@/assets/video/VESCH_ShowReel_rf36.00.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <div
        class="lds-dual-ring absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 -z-10"
      ></div>
    </div>
  </div>
</template>

<script>
  import { onMounted, ref, computed } from "vue";

  export default {
    name: "IGPhoneMockup",
    setup() {
      const video = ref();

      const autoplay = computed(
        () => video.value && video.value.offsetParent != null
      );

      onMounted(() => {
        if ("IntersectionObserver" in window) {
          var lazyVideoObserver = new IntersectionObserver(function (entries) {
            entries.forEach(function (video) {
              if (video.isIntersecting) {
                for (var source in video.target.children) {
                  var videoSource = video.target.children[source];
                  if (
                    typeof videoSource.tagName === "string" &&
                    videoSource.tagName === "SOURCE"
                  ) {
                    videoSource.src = videoSource.dataset.src;
                  }
                }

                video.target.load();
                video.target.muted = true;
                video.target.autoplay = true;
                video.target.classList.remove("lazy");
                lazyVideoObserver.unobserve(video.target);
              }
            });
          });

          lazyVideoObserver.observe(video.value);
        }
      });

      return {
        video,
        autoplay,
      };
    },
  };
</script>

<style scoped>
  .frame {
    width: 100%;
    max-width: 360px;
  }
  .banner-video {
    z-index: 1;
    position: absolute;
    top: 7.5%;
    left: 5.5%;
    width: 89.3%;
    height: 83.5%;
    overflow: hidden;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
  }
</style>
