<template>
  <Teleport to="head">
    <component :is="'script'" type="text/javascript" v-if="cookieConsented">
      (function (w, d, s, l, i) { w[l] = w[l] || []; w[l].push({ "gtm.start":
      new Date().getTime(), event: "gtm.js", }); var f =
      d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l !=
      "dataLayer" ? "&l=" + l : ""; j.async = true; j.src =
      "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f); })(window, document, "script",
      "dataLayer", "GTM-N9P7FR");
    </component>
  </Teleport>
  <div class="bg-dark-blue text-white pt-20">
    <Navbar />
    <router-view @showCookieConsent="showCookieConsent = true" />
    <Footer />
    <CookieConsent v-model:showCookieConsent="showCookieConsent" />
  </div>
</template>

<script>
  import { ref, computed } from "vue";
  import Navbar from "./components/Navbar.vue";
  import Footer from "./components/Footer.vue";
  import CookieConsent from "./components/CookieConsent.vue";
  export default {
    name: "App",
    components: {
      Navbar,
      Footer,
      CookieConsent,
    },
    setup() {
      const showCookieConsent = ref(false);

      const cookieConsented = computed(() =>
        localStorage.getItem("cookies.acceptAll")
      );

      return {
        showCookieConsent,
        cookieConsented,
      };
    },
  };
</script>
