<template>
  <section class="" id="leistungen">
    <div
      class="container px-3 circle-element-panels mx-auto md:sticky md:top-0 md:h-screen mb-8"
    >
      <div class="md:h-full md:flex flex-col">
        <div class="pt-20 flex-grow-0">
          <SectionSubHeading accent>Services</SectionSubHeading>
          <SectionHeading white
            >Technologie
            <span class="text-accent">&</span> Fantasie</SectionHeading
          >
        </div>
        <div class="md:h-full flex-grow md:flex">
          <div class="md:flex portrait:flex-col items-center md:w-full">
            <div
              class="md:w-2/3 md-landscape:w-1/2 md-landscape:h-full relative"
            >
              <div
                class="max-h-[70vh] md:max-h-[80%] md:w-10/12 md-landscape:w-full mx-auto my-8 md-landscape:my-0 md-landscape:h-full md-landscape:absolute md-landscape:top-1/2 md-landscape:left-1/2 md-landscape:-translate-x-1/2 md-landscape:-translate-y-1/2"
              >
                <div class="relative w-full h-full">
                  <CircleImage
                    image="Circle_Chart0.svg"
                    class="w-full h-full relative z-10"
                  />
                  <CircleImage
                    image="Circle_Chart_active.svg"
                    class="w-full h-full absolute top-0 left-0 transition-all hidden md:block"
                    :class="{
                      'rotate-0': currentActive == 0,
                      'rotate-90': currentActive == 1,
                      'rotate-180': currentActive == 2,
                      'rotate-[270deg]': currentActive == 3,
                    }"
                  />
                  <a
                    :href="'#panel' + i"
                    v-for="i in 4"
                    :key="i"
                    class="absolute w-1/2 h-1/2 z-20 hidden md:block"
                    :class="{
                      'top-0 left-0': i == 1,
                      'top-0 right-0': i == 2,
                      'bottom-0 right-0': i == 3,
                      'bottom-0 left-0': i == 4,
                    }"
                  ></a>
                </div>
              </div>
            </div>
            <CircleText ref="scene2" active="0">
              <template v-slot:title>Performance-Marketing</template>
              <p class="mt-3">
                Unser Ziel ist die messbare und nachhaltige Umsatzsteigerung für
                Deine Onlineshops und Marktplätze. Dazu kombinieren wir
                Performance-, Brand- und Content-Marketing mit eigener Redaktion
                und Creative Content Studio.
              </p>
            </CircleText>
            <CircleText ref="scene3" image="Circle_Chart0.svg" active="1">
              <template v-slot:title>Creative-Content-Studio</template>
              <p class="mt-3">
                High-Performance-Marketing braucht herausragenden Content &
                Creatives, um deine Zielgruppe mit immer wieder neuen,
                überraschenden Werbemitteln digital zu bespielen. Unsere Ads für
                Deine Marke durchlaufen regelmäßige Iterationsprozesse mit immer
                wieder neuen Creatives, die wir gegeneinander testen, um die
                besten Werbemittel zu skalieren.
              </p>
            </CircleText>
            <CircleText ref="scene4" image="Circle_Chart0.svg" active="2">
              <template v-slot:title>Customer-Experience Automation</template>
              <p class="mt-3">
                Nachhaltige Steigerung des CLV (Customer Lifetime Value) Deiner
                Kunden durch softwarebasiertes und DSGVO-gerechtes Tracking des
                Nutzerverhaltens auf deinen digitalen Kanälen zur dynamischen
                Marketingoptimierung per Newsletter, Messaging und App-
                Push-Benachrichtigungen.
              </p>
            </CircleText>
            <CircleText ref="scene5" image="Circle_Chart0.svg" active="3">
              <template v-slot:title>Development & Optimization</template>
              <p class="mt-3">
                High-End-Development – und Optimierung mit eigenen top
                Entwicklern für Deine Onlineshops, Websites, Apps und alle
                digitale Anwendungen. Dazu bieten wir Dir den Aufbau,
                Vermarktung und Wachstumsbeschleunigung Deiner Markenshops auf
                Marktplätzen wie z.B. Amazon oder OTTO.
              </p>
            </CircleText>
          </div>
        </div>
      </div>
    </div>
    <div
      v-for="i in 4"
      :id="'panel' + (i + 0)"
      :key="i"
      class="circle-element-panels h-[100vh] hidden md:block"
    ></div>
  </section>
</template>

<script>
  import { onMounted, onUnmounted, ref } from "vue";
  import SectionSubHeading from "@/components/SectionSubHeading.vue";
  import SectionHeading from "@/components/SectionHeading.vue";
  import CircleImage from "@/components/partials/CircleImage.vue";
  import CircleText from "@/components/partials/CircleText.vue";

  export default {
    name: "ServicesSection",
    components: {
      SectionSubHeading,
      SectionHeading,
      CircleImage,
      CircleText,
    },
    setup() {
      const currentActive = ref();
      const setActivePanel = (active) => {
        const panels = document.querySelectorAll(".circle-element");
        panels.forEach((panel) => {
          panel.classList.add("md:hidden");
        });
        panels[active].classList.remove("md:hidden");
        currentActive.value = active;
      };
      let oldActive = -1;
      const scroll = () => {
        const panels = document.querySelectorAll(".circle-element-panels");
        let active = 0;
        panels.forEach((panel, key) => {
          if (panel.offsetTop - panels[0].offsetTop < panels[0].clientHeight) {
            active = key;
          }
        });
        active = Math.max(active - 1, 0);
        active != oldActive && setActivePanel(active);
        oldActive = active;
      };

      onMounted(() => {
        document.addEventListener("scroll", scroll);
      });
      onUnmounted(() => {
        document.removeEventListener("scroll", scroll);
      });
      return {
        currentActive,
      };
    },
  };
</script>
