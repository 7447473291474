<template>
  <div id="ueber_uns" class="scroll-mt-9"></div>
  <section class="pt-10 md:pb-20 pb-6 lg:px-6 xl:px-0">
    <div class="container mx-auto">
      <SectionSubHeading accent>Was uns ausmacht</SectionSubHeading>
      <SectionHeading white>
        Purpose<span class="text-neon-green">,</span> Passion
        <span class="text-neon-green">&</span> Performance
      </SectionHeading>
    </div>
    <TeamSection />
    <div class="container mx-auto">
      <div
        class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 auto-rows-fr gap-5 mt-20"
      >
        <template :key="key" v-for="(item, key) in items">
          <div
            class="font-neutra text-xl xl:text-2xl bg-neon-green text-dark-blue text-center flex justify-center flex-col p-8"
          >
            <div class="max-w-sm mx-auto" v-html="item"></div>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>

<script>
  import SectionSubHeading from "./SectionSubHeading.vue";
  import SectionHeading from "./SectionHeading";
  import { ref } from "vue";
  import TeamSection from "./TeamSection.vue";

  export default {
    name: "SellingspointsSection",
    components: {
      SectionSubHeading,
      SectionHeading,
      TeamSection,
    },
    setup() {
      const items = ref([
        "Performance-, Brand- und Content-Marketing effizient orchestriert aus einer Hand",
        "Tiefes, holistisches Marketingverständnis - we love the Big Picture",
        "Langjährige Kundenbeziehungen basierend auf Leistung, Vertrauen und echter Partnerschaft",
        "Konsequent ergebnisorientiertes, unternehmerisches Denken & Handeln",
        "Integrität, Leidenschaft, Schnelligkeit, Zuverlässigkeit, Kreativität & Spaß",
        "Messbare und nachhaltige Umsatzsteigerung auf allen digitalen Kanälen",
      ]);

      return {
        items,
      };
    },
  };
</script>
