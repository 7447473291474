<template>
  <section class="min-h-screen flex flex-col justify-center" id="helden">
    <div class="container mx-auto h-full px-8">
      <div
        class="flex lg:gap-8 portrait:gap-0 text-center lg:text-left portrait:text-center justify-between"
      >
        <div class="max-w-4xl mx-auto flex flex-col justify-center order-2">
          <Heading-1>
            Neu! High<br />
            <span class="text-neon-green">Performance</span>
            Recruiting
          </Heading-1>
          <PhoneMockup class="lg:hidden portrait:block my-6" />
          <Heading-2 class="mt-6">
            Werde zur besten Arbeitgebermarke deiner Branche in deiner Region!
            Erreiche
            <span class="text-neon-green">herausragende A-Mitarbeiter</span> mit
            innovativen Social Recruiting-Methoden und attraktivem Employer
            Storytelling auf deiner Website und deinen sozialen Kanälen.
          </Heading-2>
        </div>
        <PhoneMockup class="hidden lg:block portrait:hidden order-1" />
      </div>
    </div>
  </section>
</template>

<script>
  import Heading1 from "./partials/Heading1.vue";
  import Heading2 from "./partials/Heading2.vue";
  import PhoneMockup from "./PhoneMockup.vue";
  export default {
    name: "HeldenSection",
    components: {
      Heading1,
      Heading2,
      PhoneMockup,
    },
  };
</script>
